import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Image from "../../composants/image/Image"
import LayoutTutorielFR from "../../composants/layout/fr/LayoutTutorielFR"
import Lien from "../../composants/navigation/Lien"
import Section from "../../composants/layout/Section"

import {
  obtenirImage,
  obtenirImages,
  obtenirPage,
  obtenirCartesLiensVersAutresPages,
} from "../../js/client-es/utils"
import fragmentsImages from "../../js/client-es/fragments/fragments-images"
import fragmentsPages from "../../js/client-es/fragments/fragments-pages"

export const requete = graphql`
  {
    pageYamlLogicielsAstrophotographie: allPagesCompilationYaml(
      filter: { code: { eq: "logicielsAstrophotographie" } }
    ) {
      ...FragmentPageYaml
    }
    pagesPourAllerPlusLoin: allPagesCompilationYaml(
      filter: {
        code: {
          in: ["materielAstrophotographie", "testEOSRa", "galerieVoieLactee"]
        }
      }
      sort: { fields: ordre_carte, order: ASC }
    ) {
      ...FragmentCarteLienVersPage
    }
    imagesPaysage: allImagesCompilationYaml(
      filter: {
        code: {
          in: [
            "logicielsLelogicielSequenceGeneratorPro"
            "logicielsLelogicielAstroPixelProcessor"
            "logicielsLelogicielCartesDuCiel"
            "logicielsLelogicielStellarium"
            "logicielsNina"
          ]
        }
      }
    ) {
      ...FragmentImagePaysage
    }
    imagesPortrait: allImagesCompilationYaml(
      filter: { code: { in: ["orion"] } }
    ) {
      ...FragmentImagePortraitMoyen
    }
  }
`

export default function Logicielsastrophotographie() {
  const resultatsRequete = useStaticQuery(requete)
  const page = obtenirPage(
    resultatsRequete,
    "pageYamlLogicielsAstrophotographie"
  )
  const pagesPourAllerPlusLoin = obtenirCartesLiensVersAutresPages(
    resultatsRequete,
    "pagesPourAllerPlusLoin"
  )
  const images = obtenirImages(
    resultatsRequete,
    "imagesPaysage",
    "imagesPortrait"
  )

  return (
    <LayoutTutorielFR
      page={page}
      pagesPourAllerPlusLoin={pagesPourAllerPlusLoin}
    >
      <Section titre="Préambule">
        <p>
          Voici un pêle-mêle des logiciels les plus efficaces pour pratiquer
          l’astrophotographie. En 15 années de pratique, j’en ai testé un très
          grand nombre… voici donc une petite sélection. Gardez bien en tête que
          tous vous demanderont travail et patience pour les maîtriser. Mais
          c’est la règle du jeu pour progresser en astrophotographie…
        </p>
        <Image
          image={obtenirImage(images, "orion")}
          langue={page.langue}
          afficherTitre={false}
          legende="Une mosaïque de l’intégralité de la constellation d'Orion, fruit de 15 années de pratique."
        />
      </Section>
      <Section titre="Planétariums">
        <p>
          Les planétariums sont des logiciels permettant de simuler le ciel. Ils
          sont parfaits pour préparer de sessions astrophotographiques (lever et
          coucher de Soleil, de Lune, etc.).
        </p>
        <ul>
          <li>
            <Lien urlExterne="https://stellarium.org/fr/">
              <strong>Stellarium</strong>
            </Lien>{" "}
            (Windows, Mac, Linux) : ce planétarium incontournable permet de
            simuler « tout » ciel à l’aide d’une{" "}
            <strong>vue 3D immersive</strong> : danse des constellations dans le
            ciel, passage de satellites, etc etc… Idéal pour préparer une
            session astrophotographique. Mais personnellement, son interface
            graphique me déroute un peu.
          </li>
        </ul>
        <Image
          image={obtenirImage(images, "logicielsLelogicielStellarium")}
          langue={page.langue}
        />
        <ul>
          <li>
            <Lien urlExterne="https://www.ap-i.net/skychart/fr/start">
              <strong>Cartes du ciel</strong>
            </Lien>{" "}
            (Windows, Mac, Linux) : logiciel parfait pour préparer{" "}
            <strong>des sessions d’astrophotographie de ciel profond</strong>,
            mais aussi pour piloter un télescope. C’est mon favori. Attention,
            Cartes du Ciel ne présente pas de vue « immersive » comme
            Stellarium. Comme son nom l’indique, il affiche une carte du ciel
            étoilé et est à ce titre <strong>complémentaire</strong> à ce
            dernier.
          </li>
        </ul>
        <Image
          image={obtenirImage(images, "logicielsLelogicielCartesDuCiel")}
          langue={page.langue}
        />
      </Section>
      <Section titre="Traitement d’images">
        <ul>
          <li>
            <Lien urlExterne="https://www.adobe.com/fr/products/photoshop.html">
              <strong>Photoshop</strong>
            </Lien>{" "}
            (11,99 € / mois avec Lightroom) (Windows, Mac) : l’indispensable
            logiciel pour traiter vos astrophotographies. Il demande de la
            patience pour être maîtrisé mais vous trouverez de nombreuses
            ressources sur Internet comme celle que j’ai rédigée{" "}
            <Lien codePageCible="astrophotographiePhotoshop">ici</Lien>. Bien
            sûr Photoshop est payant mais il n’existe encore aucune alternative
            gratuite aussi efficace…
          </li>
          <li>
            <Lien urlExterne="https://pixinsight.com/">
              <strong>Pixinsight</strong>
            </Lien>{" "}
            (230 €, version d’essai gratuite) (Windows, Mac, Linux) : si vous
            vous intéressez à l’astrophotographie, vous avez probablement
            entendu parler de ce logiciel. Très puissant, mais d’un maniment{" "}
            <strong>complexe</strong>, il est à réserver je pense à des
            utilisateurs <strong>avancés</strong> et <strong>passionnés</strong>
            .
          </li>
        </ul>
      </Section>
      <Section titre="Star trails">
        <ul>
          <li>
            <Lien urlExterne="https://markus-enzweiler.de/software/starstax/">
              <strong>Star Stax</strong>
            </Lien>{" "}
            (Windows, Mac) : ce logiciel permet d’empiler vos star trails. Et
            contrairement à d’autres il est capable{" "}
            <strong>de boucher les « trous »</strong> entre les traînées
            d’étoiles provoqués par l’intervalle de temps séparant deux prises
            de vue consécutives.
          </li>
        </ul>
      </Section>
      <Section titre="Traitement des images de Voie lactée">
        <ul>
          <li>
            <Lien urlExterne="https://sites.google.com/site/sequatorglobal/">
              <strong>Sequator</strong>
            </Lien>{" "}
            (Windows) : cet excellent petit logiciel gratuit possède de
            nombreuses fonctionnalités mais il permet surtout{" "}
            <strong>d’empiler une série de prises de vue identiques</strong> en
            séparant la Voie lactée (qui se déplace car la Terre tourne) et le
            sol (qui reste fixe) même si vous n’êtes pas équipés d’une monture
            équatoriale.
          </li>
          <li>
            <Lien urlExterne="https://sites.google.com/site/starrylandscapestacker/home">
              <strong>Starry Landscape Stacker</strong>
            </Lien>{" "}
            (Mac) (40 $). Il fait la même chose que Sequator, mais sur{" "}
            <strong>Mac</strong> !
          </li>
        </ul>
      </Section>
      <Section titre="Réduction de bruit">
        <ul>
          <li>
            <Lien urlExterne="https://topazlabs.com/denoise-ai/">
              <strong>Topaz Denoise AI</strong>
            </Lien>{" "}
            (80 $) (Windows, Mac) : un logiciel extrêmement efficace pour{" "}
            réduire le bruit, mais aussi{" "}
            <strong>pour gagner en résolution</strong>. Il propose également un
            plugin pour Photoshop. Attention, vous devez vous en servir avec{" "}
            <strong>parcimonie</strong> sous peine d’obtenir une image trop
            lisse et trop artificielle (le logiciel est capable d’ « inventer »
            des détails).
          </li>
        </ul>
      </Section>
      <Section titre="Prétraitement des astrophotographies du ciel profond">
        <p>
          En astrophotographie, le but du <strong>prétraitement</strong> est de
          créer une image possédant{" "}
          <strong>le moins de défauts possible</strong> (bruit, défauts
          optiques…) à partir d’une série d’images identiques. Cette image
          servira ensuite de base de travail pour réaliser les retouches
          esthétiques finales dans Photoshop. Quant au ciel profond, il s’agit
          de la discipline astrophotographique consistant à photographier les
          objets célestes se trouvant hors de notre Système Solaire (nébuleuses,
          galaxies, amas d’étoiles…).
        </p>
        <ul>
          <li>
            <Lien urlExterne="http://deepskystacker.free.fr/french/index.html">
              <strong>Deep Sky Stacker</strong>
            </Lien>{" "}
            (Windows) : Deep Sky Stacker est le logiciel{" "}
            <strong>le plus facile d’accès</strong> pour pré-tratier des
            astrophotographies du ciel profond. Je le recommande très fortement
            pour débuter. Il suffit de sélectionner de sélectionner vos prises
            de vue unitaires, vos darks, vos offsets et vos flats, et de lancer
            l’empilement.
          </li>
          <li>
            <Lien urlExterne="https://www.siril.org/fr/">
              <strong>SiriL</strong>
            </Lien>{" "}
            (Windows, Mac, Linux) : le petit dernier. Basé sur le vénérable mais
            excellent logiciel{" "}
            <Lien urlExterne="http://www.astrosurf.com/buil/iris-software.html">
              Iris
            </Lien>
            , il propose ses nombreuses fonctionnalités au travers d’une
            interface graphique au goût du jour et intuitive. Il est{" "}
            <strong>beaucoup plus puissant</strong> que Deep Sky Stacker mais
            demeure néanmoins un <strong>peu plus compliqué</strong> à utiliser.
          </li>
          <li>
            <Lien urlExterne="https://www.astropixelprocessor.com/">
              <strong>Astro Pixel Processor</strong>
            </Lien>{" "}
            (72€ / an) (Windows, Mac, Linux) : <strong>très puissant</strong>,
            très <strong>régulièrement mis à jour</strong> et doté de nombreux
            réglages, son algorithme de « debayarisation » est très performant :
            celui-ci permet de gagner des détails en comparaison d’un empilement
            réalisé sous Deep Sky Stacker. Astro Pixel Processor excelle
            également dans l’assemblage de grandes mosaïques célestes : mes
            astrophotographies «{" "}
            <Lien codePageCible="orionZoom">Orion 360</Lien> » et «{" "}
            <Lien codePageCible="megalaxyZoom">Megalaxy Zoom</Lien> » ont été
            assemblées avec. Par contre, ses très nombreux réglages et son
            interface graphique austère rendent la prise en main d’Astro Pixel
            Processor complexe.
          </li>
        </ul>
        <Image
          image={obtenirImage(images, "logicielsLelogicielAstroPixelProcessor")}
          langue={page.langue}
        />
      </Section>
      <Section titre="Traitement des astrophotographies lunaires et planétaires">
        <ul>
          <li>
            <Lien urlExterne="https://sites.google.com/site/astropipp/">
              <strong>PIPP</strong>
            </Lien>{" "}
            (Windows) : un petit logiciel extrêmement efficace pour réaliser un{" "}
            <strong>pré-traitement d’images lunaires et planétaires</strong>{" "}
            pour faciliter leur empilement dans un logiciel tiers comme
            Photoshop ou Registax. Il réalise l’alignement, la sélection des
            meilleures images et bien d’autres choses encore, comme de la
            conversion de formats vidéo.
          </li>
          <li>
            <Lien urlExterne="http://www.astronomie.be/registax/">
              <strong>Registax</strong>
            </Lien>{" "}
            (Windows) : un excellent logiciel spécialisé dans{" "}
            <strong>l’alignement</strong>, <strong>l’empilement</strong> et le{" "}
            <strong>traitement</strong> des astrophotographies{" "}
            <strong>lunaires</strong> et <strong>planétaires</strong>. Il
            possède des algorithmes de traitement puissants spécialisés pour
            cette discipline astrophotographique comme le traitement par
            ondelettes (qui permet de révéler les détails d’un disque lunaire
            par exemple).
          </li>
        </ul>
      </Section>
      <Section titre="Acquisitions">
        <ul>
          <li>
            <Lien urlExterne="https://mainsequencesoftware.com/products/sgpro/">
              <strong>Sequence Generator Pro</strong>
            </Lien>{" "}
            (149 $ puis 59 $ par an) (Windows) : ce logiciel est capable{" "}
            <strong>
              d’automatiser complètement une session d’astrophotographie du ciel
              profond
            </strong>{" "}
            : mise au point (si votre télescope est équipé d’une mise au point
            motorisée), cadrage, retournement au méridien… Il permet également
            d’automatiser complètement l’acquisition de grandes mosaïques
            célestes. La mise au point de l’automatisation d’un télescope
            peut-être longue et il faut s’armer de patience. Mais le jeu en vaut
            la chandelle, quelle tranquillité une fois que tout fonctionne…
          </li>
          <Image
            image={obtenirImage(
              images,
              "logicielsLelogicielSequenceGeneratorPro"
            )}
            langue={page.langue}
          />
          <li>
            <Lien urlExterne="https://nighttime-imaging.eu/">
              <strong>NINA</strong>
            </Lien>{" "}
            (Windows) : comme Sequence Generator Pro, ce logiciel permet{" "}
            <strong>d’automatiser complètement</strong> une session
            d’astrophotographie du ciel profond . NINA est très puissant,
            dispose des mêmes fonctionnalités que Sequence Generator Pro, mais
            il peu s’avérer un peu moins stable.
          </li>
          <Image
            image={obtenirImage(images, "logicielsNina")}
            langue={page.langue}
          />
          <li>
            <Lien urlExterne="https://astrophotography.app/index.php">
              <strong>Astro Photography Tools</strong>
            </Lien>{" "}
            (Windows) (19 €) : ce véritable couteau suisse de l’astrophotographe
            permet de gérer et même{" "}
            <strong>
              d’automatiser vos sessions d’astrophotographie du ciel profond
            </strong>
            . Il n’est toutefois pas aussi puissant que Sequence Generator Pro,
            mais il est plus simple d’utilisation. Un excellent outil si vous
            êtes équipé d’un réflex (il a été conçu pour ces appareils à
            l’origine).
          </li>
          <li>
            <Lien urlExterne="https://openphdguiding.org">
              <strong>PHD Guiding</strong>
            </Lien>{" "}
            (Windows, Mac, Linux) : le logiciel par excellence pour autoguider
            une monture équatoriale afin de corriger les erreurs de suivi lors
            de vos longues expositions à longue focale.
          </li>
        </ul>
      </Section>
    </LayoutTutorielFR>
  )
}
